<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="내부교재 출고요청"></CarrotTitle>
        <div class="board">
            <div v-if="add.is_loaded" class="mt-40">
                <div>

                    <div v-for="(irow, i) in add.books" :key="i" class="dp-table w-100per mb-10">
                        <div class="float-left w-1095px pr-10">
                            <table class="table-row">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="100">
                                    <col width="140">
                                    <col width="100">
                                    <col width="140">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th><span class="txt-red">*</span> 교재정보</th>
                                        <td>
                                            <carrot-book-title v-if="add.is_show==true" v-model:price.sync="irow.price" v-model:bookname.sync="irow.bookname" v-model="irow.idx_book" class="w-100per"></carrot-book-title>
                                        </td>
                                        <th>수량</th>
                                        <td>
                                            <input type="text" v-model.trim.number="irow.qty" @change="add.calcPrice(i)" class="w-100per txt-right" maxlength="5">
                                        </td>
                                        <th>금액</th>
                                        <td>
                                            {{ irow.price_txt }} 원
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="float-right w-105px pl-10">
                            <button v-if="i==0" @click="add.addBooks()" class="btn-default w-95px mt-10">교재추가</button>
                            <button v-else @click="add.delBooks(i)" class="btn-default w-95px mt-10">삭제</button>
                        </div>
                    </div>

                    <table class="table-row mt-40">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>요청자</th>
                                <td>
                                    {{ add.info.ename }}({{ add.info.kname }})
                                </td>
                                <th>등록일</th>
                                <td>
                                    {{ add.info.wdate }}
                                </td>
                            </tr>
                            <tr>
                                <th>출고현황</th>
                                <td colspan="3">
                                    {{ add.state_txt }}
                                </td>
                            </tr>
                            <tr>
                                <th>내부/외부</th>
                                <td colspan="3">
                                    {{ add.btype_txt }}
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 용도</th>
                                <td colspan="3">
                                    <select v-model="add.utype" class="w-200px">
                                        <option value="">선택</option>
                                        <option v-for="(irow, i) in add.utype_list" :key="i" :value="irow.code">{{ irow.name }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사명</th>
                                <td colspan="3">
                                    <carrot-company v-model="add.company" class="w-400px"></carrot-company>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 과정명</th>
                                <td colspan="3">
                                    <input type="text" v-model.trim="add.title" class="w-400px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 유/무상</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.is_paid" value="N"><span class="ml-5 mr-20"> 무상</span></label>
                                    <label><input type="radio" v-model="add.is_paid" value="Y"><span class="ml-5 mr-20"> 유상</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>무상 사유</th>
                                <td colspan="3">
                                    <input type="text" v-model.trim="add.unpaid_memo" class="w-100per" maxlength="100">
                                </td>
                            </tr>
                            <tr>
                                <th>출고희망일</th>
                                <td colspan="3">
                                    <carrot-date-picker v-model="add.request_date" class="w-110px"></carrot-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(세부내용)</th>
                                <td colspan="3">
                                    <textarea v-model.trim="add.etc_memo" class="w-100per h-100px" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- 외부 -->
                    <table class="table-row mt-40" style="display:none;">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>출판사</th>
                                <td colspan="3">
                                    캐럿글로벌
                                </td>
                            </tr>
                            <tr>
                                <th>배송 주소</th>
                                <td colspan="3">
                                    강남대로 488 대남빌딩 4층
                                </td>
                            </tr>
                            <tr>
                                <th>받는 분 성함</th>
                                <td>
                                    김민정
                                </td>
                                <th>연락처</th>
                                <td>
                                    010-0000-0000
                                </td>
                            </tr>
                            <tr>
                                <th>언어</th>
                                <td colspan="3">
                                    영어
                                </td>
                            </tr>
                            <tr>
                                <th>구입 사유</th>
                                <td colspan="3">
                                    구입 사유 내용
                                </td>
                            </tr>
                            <tr>
                                <th>구입거래처</th>
                                <td colspan="3">
                                    <select class="w-200px">
                                        <option value="">선택</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- //외부 -->

                    <button @click="add.doCancel" class="btn-default float-left mt-30">목록</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <!-- <button @click="add.doCancel" class="btn-default float-left mt-30 ml-20">삭제</button> -->
                    <!-- <button @click="add.doCancel" class="btn-default float-right mt-30">수정</button> -->
                    <!-- <button @click="add.doCancel" class="btn-default float-right mt-30 mr-20">출고 완료</button> -->
                    <!-- <button @click="add.doCancel" class="btn-default float-right mt-30 mr-20">출고 취소</button> -->
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import CarrotBookTitle from "@/components/common/CarrotBookTitle.vue"
import Swal from 'sweetalert2'


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker,
        CarrotCompany,
        CarrotBookTitle
    },
    setup() {
        const router = useRouter();
        const toast = useToast();

        const add = reactive({
            is_loaded  : false,
            utype_list : [],
            info       : {},
            is_show    : true,

            books             : [
                { idx_book:0, bookname:"", qty:0, price:"0", price_txt:"0" }
            ],
            utype             : "",
            btype_txt         : "내부",
            state_txt         : "출고",
            company           : "",
            title             : "",
            is_paid           : "N",
            unpaid_memo       : "",
            request_date      : "",
            etc_memo          : "",

            doCancel : () => {
                router.go(-1);
                // router.push({ name:"ResourceManagement-TextbookRentalList" });
            },

            getBookStaffInfo : () => {
                let params = {};

                axios.get("/rest/resourceManagement/getBookStaffInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.info = res.data;

                        add.utype_list = res.data.utype_list;

                        add.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            addBooks : () => {
                add.is_show = false;
                add.books.splice(0, 0, { idx_book:0, bookname:"", qty:0, price:"0", price_txt:"0" });
                setTimeout(function(){
                    add.is_show = true;
                }, 100);
            },

            delBooks : (i) => {
                add.books.splice(i, 1);
            },

            calcPrice : (i) => {
                let o = add.books[i];
              if(typeof o.qty === 'string' && o.qty !== null && o.qty !== undefined) {
                o.qty = o.qty.replace(/[^0-9]/gi, '');
                o.qty = (o.qty ? Number(o.qty) : 0);
              }

              if( o.qty > 0 ){
                    o.price_txt = (o.qty*o.price).toLocaleString()
                } else {
                    o.price_txt = 0
                }

                console.log(o);

                add.books[i] = o;
            },

            doSubmit : () => {
                let params = {
                    books        : add.books,
                    utype        : add.utype,
                    company      : add.company,
                    title        : add.title,
                    is_paid      : add.is_paid,
                    unpaid_memo  : add.unpaid_memo,
                    request_date : add.request_date,
                    etc_memo     : add.etc_memo,
                };

                if( params.books.length <= 0 ){
                    toast.error("교재를 추가하세요.");
                    return;
                }
                for(let i=0; i<params.books.length; i++){
                    let irow = params.books[i];

                    if( irow.idx_book <= 0 ){
                        toast.error( (i+1) + "번째 교재를 선택하세요.");
                        return;
                    }
                    if( irow.qty <= 0 ){
                        toast.error( (i+1) + "번째 교재의 출고 수량을 입력하세요.");
                        return;
                    }
                }

                if( !params.utype ){
                    toast.error("용도를 선택하세요.");
                    return;
                }
                if( !params.company ){
                    toast.error("고객사를 선택하세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("과정명을 입력하세요.");
                    return;
                }
                if( !params.is_paid ){
                    toast.error("유/무상 여부를 선택하세요.");
                    return;
                }
                if( params.is_paid == 'N' && !params.unpaid_memo ){
                    toast.error("무상 사유를 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/ckeckRental", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '출고 등록',
                            text : '등록하였습니다.'
                        }).then(() => {
                            sessionStorage.setItem('TextbookRentalList', JSON.stringify({
                                page : 1
                            }));
                            router.push({
                                name : 'ResourceManagement-TextbookRentalList'
                            })
                        });
                    } else if ( res.data.err == 200 ){
                        Swal.fire({
                            title: '출고 등록',
                            text : '재고수량을 초과했습니다. 그래도 입력하시겠습니까?',
                            showCancelButton : true
                        }).then((res) => {
                            if( res.isConfirmed == true ){
                                axios.post("/rest/resourceManagement/addRental", params).then((res) => {
                                    if( res.data.err == 0 ){
                                        Swal.fire({
                                            title: '출고 등록',
                                            text : '등록하였습니다.'
                                        }).then(() => {
                                            sessionStorage.setItem('TextbookRentalList', JSON.stringify({
                                                page : 1
                                            }));
                                            router.push({
                                                name : 'ResourceManagement-TextbookRentalList'
                                            })
                                        });
                                    } else {
                                        if(res.data.err_msg) toast.error(res.data.err_msg);
                                    }
                                });
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        onMounted(() => {
            // Mounted
            add.getBookStaffInfo();

        });

        return {add};
    }
}
</script>

<style lang="scss" scoped>
</style>